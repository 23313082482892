/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { refreshPage } from '../store/feature/authSlice';
import { setGeneralConfig } from '../store/feature/configsSlice';
import { home_page } from '../tradly.config';
import { TYPE_CONSTANT } from '../constant/Web_constant';
import { base_url } from '../constant/url';
import tradly from 'tradly';
import axios from 'axios';

const Index = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const general_configs = JSON.parse(localStorage.getItem('general_configs'));

    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_refresh_key`)) {
      dispatch(
        refreshPage({
          key: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_refresh_key`),
        })
      );
    }

    dispatch(setGeneralConfig({ general_configs: general_configs }));
  }, [dispatch]);

  const pageTitle = TYPE_CONSTANT.META_TITLE;
  const pageDescription = TYPE_CONSTANT.META_DESCRIPTIONS;

  return (
    <>
      <div>{home_page(pageTitle, pageDescription)}</div>
    </>
  );
};

export default Index;

// export async function getServerSideProps({ req, res }) {
//   var host = 'amaradda.tradly.co';
//   console.log(req.headers.host);

//   console.log(req.headers.host);
//   axios({
//     url: `${base_url()}/skua/tenants/pk_by_domain?domain=${host}&env=${
//       process.env.ENVIRONMENT
//     }`,
//     method: 'GET',
//     responseType: 'json',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })
//     .then((response) => {
//       if (response.data.status) {
//         tradly.init.config({
//           token: response.data.data.key,
//           environment: process.env.ENVIRONMENT,
//         });
//         console.log('এটা সফল হয়েছে');
//       }
//     })
//     .catch((error) => {
//       console.log(error.response.data);
//     });
//   return {
//     props: {},
//   };
// }
